<template>
    <div id="xieyi">
        <p>
            <span style="font-family: 宋体; font-size: 14px">协议</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >协议是由用户<span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体">以下简称为“您”</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体">与</span></span
            ><span style="font-family: 宋体; font-size: 14px"
                >本平台<span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体">以下简称为“我们”</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体">就我们所提供的产品和服务</span
                ><span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体"
                    >包括我们的网站以及我们现在正在提供和将来可能向您提供的网络服务和游戏服务，以下统称为“产品和服务”</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体">所订立的协议。</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >如果您获得并使用我们所提供的产品和服务，则应视为您已经详细阅读了本协议的内容，同意本协议的内容，并同意遵守本协议的规定。为保障您的权益，请于注册使用本公司所提供的各种服务之前，仔细阅读以下规定。一旦您注册成为</span
            ><span style="font-family: 宋体; font-size: 14px">游戏会员</span
            ><span style="font-family: 宋体; font-size: 14px"
                >，即表示您同意并愿意遵守以下所有规定<span
                    style="font-family: Calibri"
                    >;</span
                ><span style="font-family: 宋体"
                    >另外提醒您，此处之规定随时可能会进行适当的变更，请定期查询。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >一、关于服务内容</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">(1)</span
            ><span style="font-family: 宋体; font-size: 14px"
                >本平台网络服务的具体内容由我们根据实际情况提供，例如论坛<span
                    style="font-family: Calibri"
                    >(BBS)</span
                ><span style="font-family: 宋体"
                    >、聊天室、电子邮件、发表个人评论等</span
                ></span
            ><span style="font-family: 宋体; font-size: 14px"
                >。保留随时变更、中断或终止部分或全部网络服务的权利。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">(2)</span
            ><span style="font-family: 宋体; font-size: 14px"
                >本平台在提供网络服务时，可能会对部分网络服务用户收取一定的费用。在此情况下，会在相关页面上做明确的提示。如用户不同意支付该等费用，则可不接受相关的网络服务。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(3)<span style="font-family: 宋体"
                    >用户理解：仅提供相关的网络服务，除此之外与相关网络服务有关的设备</span
                ><span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体"
                    >如电脑、调制解调器及其他与接入互联网有关的装置</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体">及所需的费用</span
                ><span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体"
                    >如为接入互联网而支付的电话费及上网费</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体"
                    >均应由用户自行负担。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(4)<span style="font-family: 宋体"
                    >用户应使用正版软件接受网络服务。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >二、有关注册资料</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >1.<span style="font-family: 宋体">提供个人注册资料</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)<span style="font-family: 宋体">在申请</span></span
            ><span style="font-family: 宋体; font-size: 14px"
                >手游平台通行证会员账号时，您向我们提供的是及时、详细及准确的个人资料<span
                    style="font-family: Calibri"
                    >;</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(2)<span style="font-family: 宋体"
                    >所有由您提供的个人资料将被我们用来作为识别您和其他用户的依据</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(3)<span style="font-family: 宋体"
                    >如果您所提供的个人注册资料与事实不符，或已变更而未及时更新，或有任何误导之嫌，导致我们无法为您提供或进一步提供产品和服务，我们将不承担任何责任。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >2.<span style="font-family: 宋体">修改注册资料</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >您可以在任何时候，通过我们官方网站提供的会员服务或我们公布的其他途径，更新或修改您申请注册时所提供的资料。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >3.<span style="font-family: 宋体">个人资料的披露</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >为了向您提供产品和服务，您同意授权我们以我们认为适当的方式向第三方透露您的注册资料。但是，我们不会公开您的姓名、住址、通讯地址、电子邮箱、账号、密码等个人资料。除非：</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)<span style="font-family: 宋体"
                    >您允许我们披露这些个人资料</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(2)<span style="font-family: 宋体"
                    >有关法律法规或行政规章要求我们披露您的个人资料</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(3)<span style="font-family: 宋体"
                    >司法机关或行政机关基于法定程序要求我们披露您的个人资料</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(4)<span style="font-family: 宋体"
                    >为保护我们的知识产权和其他财产权益，需要披露您的个人资料</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(5)<span style="font-family: 宋体"
                    >在紧急情况下为保护其他用户和社会大众的人身安全，需要披露您的个人资料。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >我们将采取商业上合理的方式以保护您的个人资料的安全。我们将使用通常可以获得的安全技术和程序来保护您的个人资料不被未经授权的访问、使用或泄漏。对于非因我们的疏忽而造成您账号的丢失或您个人资料的泄密，我们不承担任何责任。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >三、关于账号和密码</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >此处指的是您注册成功的账号及密码。请了解账号在注册之后不可变更，而密码可以通过我们提供的服务进行修改。您对于您的账号及密码的保管以及使用该账号和密码所进行的一切行动负有完全的责任。请不要将账号、密码转让或出借给他人
                使用。因为您的保管疏忽或其他任何个人行为导致您的账号或密码遭他人非法使用及因此所衍生的任何后果，我们不承担任何责任。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">四、您的权利</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >1.<span style="font-family: 宋体"
                    >您可以根据本协议以及我们不时更新和公布的其他规则使用我们提供的产品和服务。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >2.<span style="font-family: 宋体"
                    >您有权在使用我们提供的产品和服务期间监督我们及我们的工作人员是否按照我们所公布的标准向您提供产品和服务，也可以随时向我们提出与我们的产品和服务有关的意见和建议。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >3.<span style="font-family: 宋体"
                    >如果您不同意本协议条款，或对我们后来更新的条款有异议，或对我们所提供的产品和服务不满意，您可以选择停止使用我们的产品和服务。如果您选择停止使用我们的产品和服务，则我们即不再对您承担任何义务和责任。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">五、您的义务</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >1.<span style="font-family: 宋体"
                    >您同意按照我们不时发布、变更和修改的本协议条款及其他规则接受并使用我们的产品和服务，您不得通过不正当的手段或其他不公平的手段使用我们的产品和服务或参与我们活动。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >2.<span style="font-family: 宋体"
                    >您不得干扰我们正常地提供产品和服务，包括但不限于：</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)<span style="font-family: 宋体"
                    >攻击、侵入我们的网站服务器或使网站服务器过载</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(2)<span style="font-family: 宋体"
                    >破解、修改我们提供的客户端程序</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(3)<span style="font-family: 宋体"
                    >攻击、侵入我们的游戏服务器或游戏服务器端程序或使游戏服务器过载</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(4)<span style="font-family: 宋体"
                    >制作、使用、发布、传播任何形式的妨碍游戏公平性的辅助工具或程序</span
                ><span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体">外挂</span
                ><span style="font-family: Calibri">);</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(5)<span style="font-family: 宋体">利用程序的漏洞和错误</span
                ><span style="font-family: Calibri">(Bug)</span
                ><span style="font-family: 宋体"
                    >破坏游戏的正常进行或传播该漏洞或错误</span
                ><span style="font-family: Calibri">(Bug);</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(6)<span style="font-family: 宋体"
                    >不合理地干扰或阻碍他人使用我们所提供的产品和服务。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >3.<span style="font-family: 宋体"
                    >您必须保管好自己的账号和密码，由于您的原因导致账号和密码泄密而造成的后果均将由您自行承担。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >4.<span style="font-family: 宋体"
                    >您仅能以一个单独的个人的身份使用我们所提供的产品和服务，您不能利用我们所提供的产品和服务从事商业目的的活动，也不能利用我们的产品和服务进行销售或其他商业目的的活动。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >5.<span style="font-family: 宋体"
                    >您需对自己账号中的所有活动和事件负责。您须遵守有关互联网信息发布的有关法律、法规及通常适用的互联网一般道德和礼仪的规范，您将自行承担您所发布的信息内容的责任。特别的，您不得发布下列内容：</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)<span style="font-family: 宋体"
                    >反对宪法所确定的基本原则的</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(2)<span style="font-family: 宋体"
                    >危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(3)<span style="font-family: 宋体">损害国家荣誉和利益的</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(4)<span style="font-family: 宋体"
                    >煽动民族仇恨、民族歧视，破坏民族团结的</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(5)<span style="font-family: 宋体"
                    >破坏国家宗教政策，宣扬邪教和封建迷信的</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(6)<span style="font-family: 宋体"
                    >散布谣言，扰乱社会秩序，破坏社会稳定的</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(7)<span style="font-family: 宋体"
                    >散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(8)<span style="font-family: 宋体"
                    >侮辱或者诽谤他人，侵害他人合法权益的</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(9)<span style="font-family: 宋体"
                    >含有法律、行政法规禁止的其他内容的。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(10)<span style="font-family: 宋体"
                    >侵犯任何第三者的知识产权，版权或公众</span
                ><span style="font-family: Calibri">/</span
                ><span style="font-family: 宋体">私人权利的。</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(11)<span style="font-family: 宋体"
                    >违反人文道德、风俗习惯的。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >6.<span style="font-family: 宋体"
                    >您的游戏账号、游戏角色，以及与我们产品相关的论坛昵称不得包含本协议五条第</span
                ><span style="font-family: Calibri">5</span
                ><span style="font-family: 宋体"
                    >款所禁止发布的内容。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">六、隐私保护</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >1.<span style="font-family: 宋体">保护用户</span
                ><span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体">特别是未成年人</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体"
                    >的隐私是的一项基本政策，因此，若父母</span
                ><span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体">监护人</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体">希望未成年人</span
                ><span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体">尤其是十岁以下子女</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体"
                    >得以使用本服务，必须以父母</span
                ><span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体">监护人</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体"
                    >名义申请注册，在接受本服务时，应以法定监护人身份加以判断本服务是否符
                    合于未成年人。 我们保证不对外公开或向第三方 </span
                ><span style="font-family: Calibri">(5.2</span
                ><span style="font-family: 宋体">所列情况除外</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体"
                    >提供用户注册资料及用户在使用网络服务时存储在的非公开内容，但下列情况除外：</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)<span style="font-family: 宋体">事先获得用户的明确授权</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(2)<span style="font-family: 宋体">根据有关的法律法规要求</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(3)<span style="font-family: 宋体"
                    >按照相关政府主管部门的要求</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(4)<span style="font-family: 宋体">为维护社会公众的利益</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(5)<span style="font-family: 宋体"
                    >为维护的合法权益。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >2.<span style="font-family: 宋体"
                    >我们可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与同等的保护用户隐私的责任，则可将用户的注册资料等提供给该第三方。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >3.<span style="font-family: 宋体"
                    >在不透露单个用户隐私资料的前提下，有权对整个用户数据库进行技术分析并对已进行分析、整理后的用户数据库进行商业上的利用。
                    尽管对用户的隐私权保护做了极大的努力，但是仍然不能保证现有的安全技术措施使用户的技术信息等不受任何形式的损失。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">七、免责声明</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">1.</span
            ><span style="font-family: 宋体; font-size: 14px"
                >本平台通行证所有者不保证以下事宜：</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)
                <span style="font-family: 宋体"
                    >本服务将符合您的要求</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(2)
                <span style="font-family: 宋体"
                    >本服务将不受干扰、及时提供、安全可靠或不会出错。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >2.<span style="font-family: 宋体"
                    >用户明确同意其使用网络服务所存在的风险将完全由其自己承担</span
                ><span style="font-family: Calibri">;</span
                ><span style="font-family: 宋体"
                    >因其使用网络服务而产生的一切后果也由其自己承担，对用户不承担任何责任。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >八、服务变更、中断或终止</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >1.<span style="font-family: 宋体"
                    >如因系统维护或升级的需要而需暂停网络服务，将尽可能事先进行通告。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >2.<span style="font-family: 宋体"
                    >如发生下列任何一种情形，有权随时中断或终止向用户提供本协议项下的网络服务而无需通知用户：</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(a)
                <span style="font-family: 宋体">用户提供的个人资料不真实</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(b)
                <span style="font-family: 宋体"
                    >用户违反本协议中规定的使用规则。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >3.<span style="font-family: 宋体"
                    >除前款所述情形外，同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络服务的权利，对于所有服务的中断或终止而造成的任何损失，无需对用户或任何第三方承担任何责任。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >4.<span style="font-family: 宋体"
                    >如果您在注册通行证第一个月时间内或连续</span
                ><span style="font-family: Calibri">6</span
                ><span style="font-family: 宋体"
                    >个月时间内，没有使用过此通行证</span
                ><span style="font-family: Calibri">(</span
                ><span style="font-family: 宋体"
                    >包括使用此通行证登录游戏，登录网站，充值</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体"
                    >，则该通行证不会被系统保留。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >九、网络游戏中虚拟物品的约定</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >游戏中的各种虚拟物品量不限于金币、银两、道具、装备等，其所有权归</span
            ><span style="font-family: 宋体; font-size: 14px"
                >本平台所有。用户只能在合乎法律和游戏规则的情况下拥有对虚拟物品的使用权。用户一旦购买了虚拟道具的使用权，将视为自动进入消费过程，不得以任何名义要求退还该虚拟道具。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >我们反对玩家自行进行线下交易，游戏内的游戏币、道具、装备等虚拟物品都是不允许线下交易的。若玩家自行线下交易出现任何问题</span
            ><span style="font-family: 宋体; font-size: 14px"
                >手游平台将不给予受理，玩家将自行负责。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">十、注销角色</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >在下列情况下，我们可以注销您创造的角色，并删除该角色的所有记录：</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >1.<span style="font-family: 宋体"
                    >您注册的游戏角色未达到一定游戏要求，并且在该角色所关联的游戏分区中可用游戏点数或游戏时间为零的情况下，连续一段时间未通过该角色登录过我们的游戏。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >2.<span style="font-family: 宋体"
                    >您自行删除的角色，自删除之日起一定时间内没有恢复的。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >3.
                <span style="font-family: 宋体"
                    >关于我们所提供的不同产品注销角色的具体条件，请参见以下各个产品的具体规定，或提供产品和服务的相关官方网站上的具体规定。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >十一、服务中断、停止和变更的说明</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >发生下列情形之一时，我们有权中断、停止或变更我们提供的服务，对于因此而产生的困扰、不便或损失，我们不承担任何责任：</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >1.<span style="font-family: 宋体"
                    >定期检查或施工，软硬件更新等，即暂停服务，我们将尽快完成维修、维护工作。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >2.<span style="font-family: 宋体"
                    >服务器遭到任何形式的破坏，无法正常运作。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >3.<span style="font-family: 宋体"
                    >网络线路或其它导致玩家通过</span
                ><span style="font-family: Calibri">Internet</span
                ><span style="font-family: 宋体"
                    >连接至我们服务器的动作发生滞碍等情形。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >4.<span style="font-family: 宋体"
                    >自然灾害等不可抗力的因素。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >5.<span style="font-family: 宋体"
                    >在紧急情况之下为维护国家安全或其它会员及第三者人身安全时。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >6.<span style="font-family: 宋体"
                    >发生突发性软硬件设备与电子通信设备故障时。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >十二、个人资料的转移和游戏方式的转变</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >我们将尽力持续地向您提供产品和服务，但是我们并不排除我们可能会停止提供
                该产品和服务的可能性，也不排除任何改变游戏方式的可能性。如果我们停止或改变提供产品和服务，我们将会事先通知您，并尽力寻找适当的服务提供者或方式以
                代替我们继续提供产品和服务。在这种情况下，我们可能会将您的个人资料<span
                    style="font-family: Calibri"
                    >(</span
                ><span style="font-family: 宋体"
                    >包括有关的账号和密码信息及个人资料</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体"
                    >转移给该继续提供服务的一方，也可能根据需
                    要永久封存原有资料以推行新的游戏方式。您在此同意我们有权做此转移和改变，并且同意在我们完成转移和改变之后，我们即不再对您原有资料承担任何义务和责
                    任。但是，我们并不在此保证我们届时肯定能够找到适当的服务提供者或服务方式以代替我们继续提供产品和服务，也不保证我们找到的服务提供者所提供的产品和
                    服务以及改变的游戏方式能够满足您的要求。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >十三、资费政策</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >我们将有权决定我们所提供的产品和服务的资费标准和收费方式，我们可能会就
                不同的产品和服务制定不同的资费标准和收费方式，也可能按照我们所提供的产品和服务的不同阶段确定不同的资费标准和收费方式。另外，我们也可能不时地修改
                我们的资费政策。我们会将有关产品和服务的收费信息以及与该产品和服务有关的资费标准、收费方式、购买方式或其他有关资费政策的信息放置在该产品和服务相
                关网页的显著位置。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >对于我们的收费产品和服务，您应该按照我们确定的资费政策购买我们的产品和服务。如果您未按我们确定的资费政策购买我们的产品和服务，我们可以立即停止向您提供该产品和服务。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >十四、游戏版本更新变动的说明</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >为增加并丰富游戏的内容，游戏在运行时会不定期更新并调整改变游戏内所有的功能。在游戏更新后，一切游戏内的操作、内容、设定将以游戏中的公告设定为准。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >十五、有限保证及责任限制</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >对于我们的产品和服务，我们仅作下述有限保证，该有限保证取代任何文档、包装、或其他资料中的任何其他明示或默示的保证<span
                    style="font-family: Calibri"
                    >(</span
                ><span style="font-family: 宋体">如果有</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体">。</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >我们仅以“现有状况且包含所有错误”的形式提供相关的产品、软件或程序及任何支持服务，并仅保证：</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)<span style="font-family: 宋体"
                    >我们所提供的产品和服务能基本符合我们正式公布的要求</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(2)<span style="font-family: 宋体"
                    >我们所提供的相关产品和服务基本与我们正式公布的服务承诺相符</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(3)<span style="font-family: 宋体"
                    >我们仅在商业上允许的合理范围内尽力解决我们在提供产品和服务过程中所遇到的任何问题。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >在适用法律允许的最大范围内，我们明确表示不提供任何其他类型的保证，不论是明示的或默示的，包括但不限于适销性、适用性、可靠性、准确性、完整性、无病毒以及无错误的任何默示保证和责任。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >另外，在适用法律允许的最大范围内，我们并不担保我们所提供的产品和服务一定能满足您的要求，也不担保提供的产品和服务不会被中断，并且对产品和服务的及时性，安全性，出错发生以及信息是否能准确、及时、顺利地传送均不作任何担保。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >在适用法律允许的最大范围内，我们不就因您使用我们的产品和服务引起的，或
                在任何方面与我们的产品和服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求<span
                    style="font-family: Calibri"
                    >(</span
                ><span style="font-family: 宋体"
                    >包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理
                    谨慎在内的任何责任、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体">承担任何责任。</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >十六、游戏管理</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >1.<span style="font-family: 宋体"
                    >资料转移：在出现本公司游戏服务器合并情形时，本公司有权将用户的角色挡案转移到其它本公司游戏服务器。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">2.</span
            ><span style="font-family: 宋体; font-size: 14px"
                >本平台通行证服务使用者守则</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)<span style="font-family: 宋体">遵守国家法律法规</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(2)<span style="font-family: 宋体"
                    >严禁修改、破译或进行任何影响游戏程序和游戏网络数据传输封包的行为</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(3)<span style="font-family: 宋体"
                    >严禁使用任何方法对服务器进行恶意入侵、攻击，或任何破坏网络服务正常运行的行为</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(4)<span style="font-family: 宋体">禁止利用游戏</span
                ><span style="font-family: Calibri">Bug</span
                ><span style="font-family: 宋体"
                    >和第三方外挂程序进行赚取金钱、复制装备、快速累积经验值等破坏游戏公平性及造成服务器负担加重的行为</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(5)<span style="font-family: 宋体"
                    >禁止公开或私下传播任何存在并可利用的游戏</span
                ><span style="font-family: Calibri">Bug</span
                ><span style="font-family: 宋体"
                    >，请在发现该类问题时立即向我们反映</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(6)<span style="font-family: 宋体"
                    >禁止使用含有人身攻击、淫秽、辱骂、反动及其他危害本游戏形象、同</span
                ><span style="font-family: Calibri">GM</span
                ><span style="font-family: 宋体"
                    >角色名故意相似扰乱正常服务秩序以及国家利益和社会公德性质的角色名</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(7)<span style="font-family: 宋体"
                    >禁止使用公共频道恶意刷屏，或做出任何影响正常游戏秩序的行为</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(8)<span style="font-family: 宋体"
                    >任何侵犯以上条款的违规行为，</span
                ><span style="font-family: Calibri">GM</span
                ><span style="font-family: 宋体"
                    >有权根据情节轻重对违规者进行警告、强制下线、禁闭、改名、删除人物直至封停账号的处罚，情节尤其严重者，将进一步追究法律责任，违规造成的点卡损失等后果，由当事的</span
                ></span
            ><span style="font-family: 宋体; font-size: 14px"
                >服务使用者自行承担<span style="font-family: Calibri"
                    >;</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >3.GM<span style="font-family: 宋体">身份说明</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)GM<span style="font-family: 宋体">即</span
                ><span style="font-family: Calibri">Game Master</span
                ><span style="font-family: 宋体"
                    >，指的是维护和管理游戏虚拟世界秩序的线上游戏管理员</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(2)GM<span style="font-family: 宋体"
                    >不会干预游戏的正常秩序，不会以任何方式索要玩家的个人资料和密码，并不负责解决玩家之间的私人纠纷以及游戏的攻略等问题</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(3)<span style="font-family: 宋体"
                    >请在游戏中尊重、理解并配合</span
                ><span style="font-family: Calibri">GM</span
                ><span style="font-family: 宋体"
                    >的工作，如有任何意见，请通过专用信箱向客服中心申诉和举报</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >4.<span style="font-family: 宋体">虚拟物品说明</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)<span style="font-family: 宋体">不推荐</span></span
            ><span style="font-family: 宋体; font-size: 14px"
                >本平台服务使用者进行任何未经过我们认证的真实的货币或点卡等物品与账号、虚拟货币和虚拟物品之间的交易，我们对由此所造成的任何纠纷和后果概不负责<span
                    style="font-family: Calibri"
                    >;</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(2)<span style="font-family: 宋体"
                    >请提高警惕，谨防服务中的欺骗行为，由于</span
                ></span
            ><span style="font-family: 宋体; font-size: 14px"
                >本平台服务使用者个人不慎而造成的账号、虚拟物品和点卡被骗或其他任何后果，我们可以提供支持帮助，但不负责赔偿或追回受骗的损失<span
                    style="font-family: Calibri"
                    >;</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(3)<span style="font-family: 宋体"
                    >除服务器大规模断线外，由于地方网络问题、个人操作问题等个别原因造成的角色被删或回档、以及虚拟物品和金钱的损失，我们不负有责任</span
                ><span style="font-family: Calibri">;</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >5.<span style="font-family: 宋体"
                    >关于游戏内玩家与玩家之间的对战：</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >(1)<span style="font-family: 宋体"
                    >我们的游戏虚拟世界内会有部分的区域设置有用户玩家之间的强制性对战。接受本协议即默认接受强制性对战的游戏设置，请玩家谨慎选择。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >十七、风险承担</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >您同意使用我们提供的服务是出于您的个人意愿，并愿自负任何风险，包括但不限于您因执行我们所提供的服务程序或自行由官方网站下载游戏或资料而导致您或您所使用的计算机系统受到损害或发生任何资料的流失等。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >注意：玩家个人操作导致出错的任何结果，由玩家自行负责，官方不给予任何形式的赔偿<span
                    style="font-family: Calibri"
                    >!</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >十八、损害赔偿</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >您若违反本协议或相关法令，导致我们的母公司、子公司、附属公司、关系企业、受雇人、代理人及其他一切相关履行辅助人员因此受到损害或支出一切衍生费用<span
                    style="font-family: Calibri"
                    >(</span
                ><span style="font-family: 宋体"
                    >包括但不限于支付上述人士须就您的违反行为所进行的一切辩护或索偿诉讼及
                    相关和解之法律费用</span
                ><span style="font-family: Calibri">)</span
                ><span style="font-family: 宋体"
                    >，您应负担损害赔偿及费用承担责任。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >十九、公司拥有终止权</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >您应确实遵守本协议及有关法律命令的规定。我们对于您是否违反本协议拥有最
                终决定权。若我们认定您违反本协议或任何法令，我们有权在无需向您进行事先通知的情况之下，立即暂停及终止或删除您账号与您账号中的所有相关资料、档案及
                任何记录，以及取消、停止、限制您的会员资格。如有系统故障、<span
                    style="font-family: Calibri"
                    >Bug</span
                ><span style="font-family: 宋体"
                    >、程序出错等等问题，我们有权把游戏的资料还原到一定日期，以维护游戏内之平衡。您不
                    得因此要求补偿或赔偿。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >为了向您提供产品和服务，我们可能会需要定期或不定期地对有关的网站服务器或游戏服务器或我们所提供的产品和服务的相关官方网站进行维护，该维护可能造成服务的中断，并且该中断可能没有事先的通知，对于由此引起的中断我们不承担任何责任。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >您已经了解并同意对于因我们、我们的合作方或电信网络系统软硬件设备的故
                障、失灵或人为操作的疏失而全部或部分中断、暂时无法使用、迟延并造成我们提供产品和服务的停止或中断的，我们不承担任何责任<span
                    style="font-family: Calibri"
                    >;</span
                ><span style="font-family: 宋体"
                    >对于因他人侵入我们的网络
                    或游戏系统，篡改、删改或伪造、变造网站和游戏资料或数据而造成我们的产品和服务的停止或中断，我们也不承担任何责任。</span
                ></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >二十、关于广告</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >我们的线上游戏软件上可能刊登商业广告或其它活动促销的广告。这些内容系广告商或商品服务提供者所为，我们仅提供刊登内容的媒介。您通过我们或我们所链接的网站所购买的服务或商品，其交易行为仅存于您与该商品或服务的提供者之间，与我们无关。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >二十一、至第三方站点的链接</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >您可能在使用我们的产品和服务过程中链接到第三方的站点。第三方的站点不由
                我们控制，并且我们也不对任何第三方站点的内容、第三方站点包含的任何链接、或第三方站点的任何更改或更新负责。我们仅为了提供便利的目的而向您提供这些
                到第三方站点的链接，我们所提供的这些链接并不意味着我们认可该第三方站点。您需要检查并遵守该第三方站点的相关规定。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">二十二、</span
            ><span style="font-family: 宋体; font-size: 14px"
                >法律适用及争议解决</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">1.</span
            ><span style="font-family: 宋体; font-size: 14px"
                >本协议的解释、效力及适用以中华人民共和国法律为依据。如果本协议的任何内容与法律相抵触，应以法律规定为准。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">2.</span
            ><span style="font-family: 宋体; font-size: 14px"
                >本协议所定的任何条款的一部或全部无效者，不影响其它条款的效力。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">3</span
            ><span style="font-family: 宋体; font-size: 14px">.</span
            ><span style="font-family: 宋体; font-size: 14px"
                >本协议自用户接受本协议之条款起生效，至</span
            ><span style="font-family: 宋体; font-size: 14px">巨</span
            ><span style="font-family: 宋体; font-size: 14px">乐</span
            ><span style="font-family: 宋体; font-size: 14px">玩</span
            ><span style="font-family: 宋体; font-size: 14px"
                >停止运营之日起终止。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">4.</span
            ><span style="font-family: 宋体; font-size: 14px"
                >本协议的解释、效力及纠纷的解决，适用于中华人民共和国法律。若用户和</span
            ><span style="font-family: 宋体; font-size: 14px"
                >之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，诉诸绍兴市上虞区人民法院。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >二十四、修改和解释权</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >基于对我们本身、用户及市场状况不断变化的考虑，我们保留随时修改、新增、
                删除本协议条款的权利，修改、新增、删除本协议条款时，我们将于官方网站首页公告修改、新增、删除的事实，而不另行对您进行个别通知。若您不同意我们所修
                改、新增、删除的内容，可停止使用我们所提供的服务。若您继续使用我们的服务，则视同您同意并接受本协议条款修改、新增、删除后之一切内容，且不得因此而
                要求任何补偿或赔偿。</span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >本协议自您登录</span
            ><span style="font-family: 宋体; font-size: 14px">系统</span
            ><span style="font-family: 宋体; font-size: 14px"
                >并点击<span style="font-family: Calibri">&quot;</span
                ><span style="font-family: 宋体">我已同意《</span></span
            ><span style="font-family: 宋体; font-size: 14px">用户</span
            ><span style="font-family: 宋体; font-size: 14px"
                >协议》<span style="font-family: Calibri">&quot;</span
                ><span style="font-family: 宋体">时生效。</span></span
            >
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
        </p>
        <p>
            <span style="font-family: 宋体; font-size: 14px"
                >我们保留对本协议条款、产品和服务以及我们所提供的产品和服务的相关官方网站的最终解释权。</span
            ><span style="font-family: 宋体; font-size: 14px"
                ><br clear="all" style="page-break-before: always"
            /></span>
        </p>
        <p>
            <br />
        </p>
    </div>
</template>

<script setup>
</script>

<style lang="less">
body {
    font-family: "微软雅黑";
    font-size: 16px;
    line-height: 26px;
    margin: 0px;
    padding: 0px;
    color: #4d4d4d;
}
#xieyi {
    margin: 0px;
    padding: 20px;
}
#xieyi p {
    text-indent: 2em;
    margin: 0px;
    padding: 0px;
}
.yans {
    font-weight: bold;
    margin-top: 10px;
}
</style>
